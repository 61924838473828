import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Wednesday 13 July 2022 we will be making the following changes to our range:</p>
      <p><strong>Summary of changes</strong></p>
      <p><strong>UK Residential First Time Buyer Range</strong></p>
      <ul>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80% and 85% LTV have increased</li>
      </ul>
      <p><strong>UK Residential Home Mover Range</strong></p>
      <ul>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80% and 85% LTV have increased</li>
      </ul>
      <p><strong>UK Residential Remortgage Range</strong></p>
      <ul>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60% LTV has increased</li>
      </ul>
      <p><strong>UK Buy to Let Remortgage Range</strong></p>
      <ul>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      </ul>
      <p><strong>UK Buy to Let Purchase Range</strong></p>
      <ul>
        <li>2 Year Fixed Fee Saver 60% LTV has increased</li>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      </ul>
      <p><strong>International Residential Range</strong></p>
      <ul>
        <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
        <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 70% and 75% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 70% and 75% LTV have increased</li>
      </ul>
      <p>We have removed from sale our 3 Year Fixed Rate Fee Saver and 3 Year Fixed Rate Standard offerings across First Time Buyers, Home Movers and Remortgage ranges as well as our 2 and 5 Year Fixed Rate Standard offerings for First Time Buyers, Home Movers and Existing Customers Switching/Borrowing More ranges at 95% LTV. Our 2 Year Fixed Rate Fee Saver proposition has also been removed from sale for BTL Home Movers at 65% and 75% LTV. </p>
      <p>There are no changes to any other interest rates.</p>
      <p>An updated mortgage rate sheet will be published on Wednesday 13 July 2022 to reflect these changes.</p>
     <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
    </NewsArticle>
)

export default Article